import React, { useState } from "react";

const ResultSection = () => {

  
  const userData = sessionStorage.getItem('userData');
  console.log(userData);
  
  var user = '';
  
  if(userData) {
   user = JSON.parse(userData);
  }
  /*
  {
    "name": "Satish Kumar",
    "dob": "1978-12-23",
    "time": "07:15",
    "place": "Patna, Bihar, India",
    "gender": "male",
    "lat": 25.5940947,
    "lng": 85.1375645,
    "nid": "37",
      
}
  
  {
    "asc_sign_number": 9,
    "past_present_karma": "In previous lives, you may have been a seeker of knowledge and truth, perhaps traveling extensively. This time, channel that wanderlust into meaningful pursuits and philosophical exploration, and ensure that your quest for wisdom includes sharing your insights with others.",
    "table_birth": [
        {
            "planet": "Sun",
            "house": 1,
            "sign": 9,
            "degree": 7,
            "minute": 30,
            "retro": "false",
            "two12": [
                2,
                12
            ]
        }
    ],
    "detailed_data": {
        "1": {
            "planet": [
                "Sun",
                "Mars"
            ],
            "retro": "false",
            "ruled_by_sign": "Aries",
            "ruled_by_planet": "Mars",
            "family_relationships": "Husband, Brother",
            "causative_of": "Police, Power, Ego, Enemies, Electricity, Metal, Second Brother, Fire, Heat, Hard, Teeth, Husband in a Female Horoscope, Blood",
            "house_direction": "East",
            "conjuction": "Sun+Mars+",
            "conjuction_ordered": "SUN+MARS",
            "trine": "Sun+Mars+Saturn+",
            "two12": "Ketu+Mercury+",
            "predictions": "CA:Father is short-tempered.<br><br>",
            "friend_enemy_exception": "Mars and Sun: Friend,Mars and Saturn: Neutral,",
            "planet_explain_definition_family": "Sun(Trine):Authority+Leadership+Soul+Health+Government+Power,Saturn(Trine):Hard Work+Discipline+Delay+Longevity+Karma+Sorrow+Service,Ketu (2,12):Roots+Culture+Detach+Spirituality+Maternal+Small+Mystic+Introspection,Mercury (2,12):Intellect+Communication+Logic+Trade+Business+Quick Thinking+Speech,",
            "planet_explain_definition": "Sun(Trine):King+Minister+Glow+Status+Eminent-person, Anger,Mars(Trine):Hardwork+Brother+Power+Ego+Enemies+Electricity+Metal+Fire+Heat+Hard+Teeth+Blood+Police+Accidents,Saturn(Trine):Karma, Ishwara+Chin+Slow+Lazy+Sincere Worker,Ketu (2,12): Roots+Culture+Detach+Spirituality+Maternal+Small+Mystic+Introspection,Mercury (2,12): Intellect+Communication+Logic+Trade+Business+Quick Thinking+Speech,"
        },
    }
}
  */
  
  var user_birth_data = '';

  if(user) {
    user_birth_data = (user.birth_data ? JSON.parse(user.birth_data) : ''); 
   // console.log(user_birth_data.past_present_karma);
  }



  //POPUP
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [email, setEmail] = useState("");

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setEmail(""); // Clear email on close
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Email:", email);
    // You can add your email submission logic here
    handleClosePopup();
  };

  return (
    <div className="relative h-screen w-full bg-cover bg-center flex flex-col justify-center items-center text-center bg-white text-gray-800 px-4" style={{ backgroundImage: `url('./images/astro.jpg')` }}>
      
      <div className="form max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="sm:text-2xl md:text-3xl lg:text-5xl  font-bold  p-1">{user ? 'Hi ' + user.name : ''} </h2>

        <h2 className="sm:text-2xl md:text-3xl lg:text-3xl p-3 font-bold">{user ? 'Past life Karma and Karma to Pay in This Life '  : ''} </h2>
        <p className="mt-4 sm:text-2xl md:text-3xl lg:text-3xl text-white width-90">
            {user_birth_data.past_present_karma ? user_birth_data.past_present_karma: ''}
        </p>
        <button className="mt-8 sm:text-2xl md:text-2xl lg:text-2xl  font-bold px-6 py-3 bg-purple-700 text-white rounded-full hover:bg-purple-800 transition" onClick={handleOpenPopup}>
          Get Your Full Report
        </button>
      </div>

    {/* Popup */}
    {isPopupVisible && (
        <div style={popupStyles.overlay}>
          <div style={popupStyles.popup}>
            <button onClick={handleClosePopup} style={popupStyles.closeButton}>
              &times;
            </button>
            <h3>Enter Your Email</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={popupStyles.input}
              />
              <button type="submit" className="mt-8 px-6 py-3 bg-purple-700 text-white rounded-full hover:bg-purple-800 transition"  style={popupStyles.submitButton}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
  </div>
  );
};
const popupStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    background: "#fff",
    opacity: "0.9",
    color: "#000",
    borderRadius: "8px",
    padding: "20px",
    textAlign: "center",
    width: "300px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    float: "right",
    top: "10px",
    right: "10px",
    color: "#000",
    border: "0px solid",
    background: "transparent",
    fontSize: "18px",
    cursor: "pointer",
  },
  input: {
    width: "100%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  submitButton: {
    padding: "10px 20px",
    background: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
export default ResultSection;
