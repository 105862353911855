import React, { useRef } from "react";
import HeroSection from "./components/HeroSection";
import QuestionSteps from "./components/QuestionSteps";
import ResultSection from "./components/ResultSection";

function App() {

  const questionStepsRef = useRef(null);
  const resultStepsRef = useRef(null);

  const scrollToQuestions = () => {
    questionStepsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToResult = () => {
    resultStepsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="bg-gradient-to-b from-blue-200 to-purple-500 min-h-screen text-gray-800">
      <HeroSection onStartJourneyClick={scrollToQuestions} />
      <div ref={questionStepsRef}>
        <QuestionSteps onStartJourneyClick={scrollToResult} />
      </div>
        
      <div ref={resultStepsRef}>
        <ResultSection />
      </div>
    </div>
  );
}

export default App;

