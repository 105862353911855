import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";
import Cookies from "js-cookie";


const QuestionSteps = ({ onStartJourneyClick }) => {
  const [googleapikey, setGoogleapikey] = useState("AIzaSyBaw3m1eDDQodEOrnBxM8j6V5SjFdHm1LE");

    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [place, setPlace] = useState("");
    const [gender, setGender] = useState("");
    
    const saveToSession = (key, value) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    };
    
    /*
      const userData = sessionStorage.getItem('userData');

    const [customerid, setCustomerid] = useState(() => {
      const data = sessionStorage.getItem('customer_id');
      return data ? JSON.parse(data) : 0 ;
    });


    const [customername, setCustomername] = useState(() => {
      const data = sessionStorage.getItem('customer_name');
      return data ? JSON.parse(data) : 0 ;
    });

    const [customerbirthdata, setCustomerbirthdata] = useState(() => {
      const data = sessionStorage.getItem('customer_data');
      return data ? JSON.parse(data) : 0 ;
    });
    */

    const handleGenderChange = (event) => {
      setGender(event.target.value);
    };

    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (autocompleteInstance) => {
      setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
      if (autocomplete) {
        const place = autocomplete.getPlace();
        setPlace(place.formatted_address);
      }
    };


    // Replace this with your actual geocoding API (e.g., Google Maps API)
    const getCoordinates = async (place) => {
      const API_KEY = "AIzaSyBaw3m1eDDQodEOrnBxM8j6V5SjFdHm1LE"; // Replace with your geocoding API key
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${API_KEY}`
        );
        const location = response.data.results[0]?.geometry?.location;
        return {
          lat: location.lat,
          lng: location.lng,
        };
      } catch (error) {
        throw new Error("Failed to fetch coordinates " + `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${API_KEY}`);
      }
    };
  
    const onSubmit = async (data) => {
      setLoading(true);
      setError(null);
      setSuccess(false);
  
      
        const { name, dob, time } = data;
  
        // Get Latitude and Longitude
        const { lat, lng } = await getCoordinates(place);
        //alert("lat"+lat);
        // Submit to your REST API
        const payload = {
          name,
          dob,
          time,
          place,
          gender,
          lat,
          lng,
        };
  
          
    try{
        const response = await axios.post("https://soulpath.bucklit.com.au/api/birthdata.php", 
          payload,
          { 
            headers: {
              "Content-Type": "application/json",
            }
        }
        )
        var responseData =JSON.parse(JSON.stringify(response.data));
        
        if(responseData['success'] == 'yes') {console.log("Response Data:", responseData);


          var karma = responseData['past_present_karma'];
          var nid = responseData['nid'];
          var birth_data = responseData['birth_data'];
          
          saveToSession('userData', { name: name,
            dob: dob,
            time: time,
            place: place,
            gender: gender,
            lat: lat,
            lng: lng,
            nid: nid,
            birth_data: birth_data,
            karma:karma
          });

          /*
          useEffect(() => {
            sessionStorage.setItem('customer_id', JSON.stringify(nid));
          }, [nid]);

          useEffect(() => {
            sessionStorage.setItem('customer_name', JSON.stringify(name));
          }, [name]);
          
          useEffect(() => {
            sessionStorage.setItem('customer_name', JSON.stringify(birth_data));
          }, [birth_data]);
          */
          
          //save to cookie 'datasets'
          let datasets = [
            {
              nid:nid,
              name: name,
              dob: dob,
              gender: gender,
              time: time,
              place: place,
              lat: lat,
              lng: lng,
              birth_data: birth_data
            }
            ];
          let x = JSON.stringify(datasets)
          
          //(Cookies.set('datasets', x, { expires: 7 }); // Set cookie expiry to 7 days
        }
        //--------

        setSuccess(true);
        onStartJourneyClick(name);
        //ResultSection(name, birth_data);
        //reset(); // Clear form after successful submission
      } catch (err) {
        setError(err.message || "An error occurred while submitting the form.");
      } finally {
        setLoading(false);
      }
    };
  
  

  

  return (
    <div className="relative h-screen w-full bg-cover bg-center text-center flex flex-col items-center justify-center px-4" style={{ backgroundImage: `url('./images/astro.jpg')` }}>
      


      <div className="form max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="sm:text-2xl md:text-3xl lg:text-5xl  font-bold mb-4">Birth Details Form</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="name" className="block sm:text-1xl md:text-2xl lg:text-3xl font-medium text-white-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              {...register("name", { required: true })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 "
              placeholder="Your Name"
            />
          </div>
          <div>
            <label htmlFor="gender" className="block sm:text-1xl md:text-2xl lg:text-3xl font-medium text-white-700">
              Gender
            </label>
            <select id="gender" 
              value={gender} 
              onChange={handleGenderChange}
              className="font-medium text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
              <option value="" disabled>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="dob" className="block sm:text-1xl md:text-2xl lg:text-3xl  font-medium">
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              {...register("dob", { required: true })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="time" className="block sm:text-1xl md:text-2xl lg:text-3xl  font-medium ">
              Time of Birth
            </label>
            <input
              type="time"
              id="time"
              {...register("time", { required: true })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="place" className="block sm:text-1xl md:text-2xl lg:text-3xl font-medium ">
              Place of Birth
            </label>
            {/* <input
              type="text"
              id="place"
              {...register("place", { required: true })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="City, State, Country"
            /> */}
            <LoadScript googleMapsApiKey={googleapikey} libraries={["places"]}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                id="place"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Search your place of birth"
              />
            </Autocomplete>
          </LoadScript>
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
        {error && <p className="mt-4 text-red-500 text-sm">{error}</p>}
        {success && <p className="mt-4 text-green-500 text-sm">Form submitted successfully!</p>}
      </div>

      <div className="mt-8 flex justify-between w-full max-w-xs">
        {/* <button
          onClick={handlePrevious}
          disabled={currentStep === 0}
          className={`px-4 py-2 bg-blue-500 text-white rounded-lg ${
            currentStep === 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentStep === dummySteps.length - 1}
          className={`px-4 py-2 bg-blue-500 text-white rounded-lg ${
            currentStep === dummySteps.length - 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-600"
          }`}
        >
          Next
        </button> */}
      </div>
    </div>
  );
};

export default QuestionSteps;
