import React from "react";
import './StarLight.css'; // Import custom CSS for star animation

const HeroSection = ({ onStartJourneyClick }) => {
  return (
    <div className="relative h-screen w-full bg-cover bg-center flex flex-col justify-center items-center text-center"
    style={{ backgroundImage: `url('./images/astro.jpg')` }}>
 
      <div className="form">
        <h1 className="sm:text-4xl md:text-4xl lg:text-5xl font-bold text-white animate-fadeIn">
          SoulPath Navigator
        </h1>
        <p className="mt-4 sm:text-1xl md:text-2xl lg:text-3xl  text-gray-100 animate-fadeIn">
          Discover your purpose, karma, and path to enlightenment.
        </p>
        <button
          onClick={onStartJourneyClick}
          className="mt-8 font-bold sm:text-1xl md:text-1xl lg:text-1xl px-6 py-3 bg-purple-700 text-white rounded-full hover:bg-purple-800 transition"
        >
          Start Your Journey
        </button>
      </div>   
      <div className="absolute inset-0 pointer-events-none  moveAsteroid">
        <div className="planet venus"></div>
        <div className="planet jupiter"></div>
        <div className="planet mars"></div>
        {/* <div className="planet saturn"></div> */}
      </div>

      {/* Star lights */}
      <div className="absolute inset-0 pointer-events-none">
          <div className="star star-1">*</div>
          <div className="star star-2">*</div>
          <div className="star star-3">*</div>
          <div className="star star-4">*</div>
          <div className="star star-5">*</div>
          <div className="star star-6">*</div>
        </div>
    </div>
  );
};

export default HeroSection;
